import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDropdown } from "../contexts/DropdownContext"; 
import logo_dark from "../assets/icons/INTTL Logo Color.png";
import logo_light from "../assets/icons/INTTL Logo White.png";
import Button from "react-bootstrap/Button"; 
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useMobileView } from "../contexts/MobileViewContext";
import CustomDropdown from "./CustomDropdown";
import DestinationDropdownItems from "../data/DestinationDropdownData";
import ExperienceDropdownItems from "../data/MiceCorporateDropdonwData";
import DMCServicesDropdownItems from "../data/DMCServicesDropdownData";
import "../components/CSS/Navbar.css";

function MyNavbar() {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const { dropdownVisible1, setDropdownVisible1, dropdownVisible2, setDropdownVisible2, dropdownVisible3, setDropdownVisible3 } = useDropdown(); 
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const isMobileView = useMobileView();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMouseEnter1 = () => {
    setDropdownVisible1(true);
  };

  const handleMouseLeave1 = () => {
    setDropdownVisible1(false);
  };

  const handleMouseEnter2 = () => {
    setDropdownVisible2(true);
  };

  const handleMouseLeave2 = () => {
    setDropdownVisible2(false);
  };

  const handleMouseEnter3 = () => {
    setDropdownVisible3(true);
  };

  const handleMouseLeave3 = () => {
    setDropdownVisible3(false);
  };

  const handleLinkClick = () => {
    setShowOffcanvas(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {["xl"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          fixed="top"
          className={`bg-body-tertiary mb-3 navbar-container ${
            scrolled ? "navbar-scrolled" : ""
          }  ${isMobileView ? "mobile-view" : ""}`}
        >
          <Container fluid className={`nav-container`}>
            <Navbar.Brand id="callOne" as={Link} to="/" onClick={scrollToTop}>
              <img
                loading="lazy"
                className="logo"
                src={scrolled ? logo_dark : logo_light}
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              className="custom-toggler"
              onClick={() => setShowOffcanvas(!showOffcanvas)}
            />
            <Navbar.Offcanvas
              className="navbar-offcanvas"
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={showOffcanvas}
              onHide={() => setShowOffcanvas(false)}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <h3 style={{ margin: "10px" }}>INTT Leisure</h3>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav
                  className={`flex-grow-1 pe-3 nav-item-container font-secondary ${
                    isMobileView ? "mobile-view" : ""
                  }`}
                >
                  {!isMobileView ? (
                    <>

                      <div
                        onMouseEnter={handleMouseEnter2}
                        onMouseLeave={handleMouseLeave2}
                      >
                        <Nav.Link
                          as={Link}
                          className={`nav-item ${
                            location.pathname === "/destinations/sri-lanka" ||
                            location.pathname === "/destinations/dubai" ||
                            location.pathname === "/destinations/indonesia" ||
                            location.pathname === "/destinations/maldives" 
                              ? "active"
                              : ""
                          } ${scrolled ? "scrolled" : "nt-scrolled"}`}
                        >
                          DESTINATIONS
                        </Nav.Link>
                      </div>

                      
                      <Nav.Link
                        className={`nav-item ${
                          location.pathname === "/magga" ? "active" : ""
                        } ${scrolled ? "scrolled" : "nt-scrolled"}`}
                        as={Link}
                        to="/"
                      >
                        MAGGA
                      </Nav.Link>

                      
                      <Nav.Link
                        className={`nav-item  ${scrolled ? "scrolled" : "nt-scrolled"}`}
                        target="_blank"
                        href="https://plusairfare.com/"
                      >
                        BOOK FLIGHTS
                      </Nav.Link>

                      <div>
                        <Nav.Link
                          as={Link}
                          to="/dmc-services"
                          className={`nav-item ${
                            location.pathname === "/dmc-services"? "active": ""
                          } ${scrolled ? "scrolled" : "nt-scrolled"}`}
                        >
                          DMC SERVICES
                        </Nav.Link>
                      </div>

                      <div
                        // onMouseEnter={handleMouseEnter1}
                        // onMouseLeave={handleMouseLeave1}
                      >
                        <Nav.Link
                          as={Link}
                          to="/mice-services"
                          className={`nav-item ${
                            location.pathname === "/mice-services" ||
                            location.pathname === "/intt-advantage"
                              ? "active"
                              : ""
                          } ${scrolled ? "scrolled" : "nt-scrolled"}`}
                        >
                          MICE & CORPORATE
                        </Nav.Link>
                      </div>

                    </>
                  ) : (
                    <>
                      <Nav.Link
                        className={`nav-item ${
                          location.pathname === "/destinations/sri-lanka" ? "active" : ""
                        } ${scrolled ? "scrolled" : "nt-scrolled"}`}
                        as={Link}
                        to="/destinations/sri-lanka"
                        onClick={handleLinkClick}
                      >
                        SRI LANKA TOURS
                      </Nav.Link>

                      <Nav.Link
                        className={`nav-item ${
                          location.pathname === "/destinations/dubai" ? "active" : ""
                        } ${scrolled ? "scrolled" : "nt-scrolled"}`}
                        as={Link}
                        to="/destinations/dubai"
                        onClick={handleLinkClick}
                      >
                        DUBAI TOURS
                      </Nav.Link>

                      <Nav.Link
                        className={`nav-item ${
                          location.pathname === "/destinations/indonesia" ? "active" : ""
                        } ${scrolled ? "scrolled" : "nt-scrolled"}`}
                        as={Link}
                        to="/destinations/indonesia"
                        onClick={handleLinkClick}
                      >
                        INDONESIA TOURS
                      </Nav.Link>

                      <Nav.Link
                        className={`nav-item ${
                          location.pathname === "/magga" ? "active" : ""
                        } ${scrolled ? "scrolled" : "nt-scrolled"}`}
                        as={Link}
                        to="/"
                        onClick={handleLinkClick}
                      >
                        MAGGA
                      </Nav.Link>

                      <Nav.Link
                        className={`nav-item ${
                          location.pathname === "/book-flights" ? "active" : ""
                        } ${scrolled ? "scrolled" : "nt-scrolled"}`}
                        target="_blank"
                        href="https://plusairfare.com/"
                        onClick={handleLinkClick}
                      >
                        BOOK FLIGHTS
                      </Nav.Link>

                      <Nav.Link
                        className={`nav-item ${
                          location.pathname === "/dmc-services" ? "active" : ""
                        } ${scrolled ? "scrolled" : "nt-scrolled"}`}
                        as={Link}
                        to="/dmc-services"
                        onClick={handleLinkClick}
                      >
                        DMC SERVICES
                      </Nav.Link>

                      <Nav.Link
                        className={`nav-item ${
                          location.pathname === "/mice-services" ? "active" : ""
                        } ${scrolled ? "scrolled" : "nt-scrolled"}`}
                        as={Link}
                        to="/mice-services"
                        onClick={handleLinkClick}
                      >
                        OUR MICE SERVICES
                      </Nav.Link>

                      {/* <Nav.Link
                        className={`nav-item ${
                          location.pathname === "/contact-us" ? "active" : ""
                        } ${scrolled ? "scrolled" : "nt-scrolled"}`}
                        as={Link}
                        to="/contact-us"
                        onClick={handleLinkClick}
                      >
                        THE INTT ADVANTAGE
                      </Nav.Link> */}
                    </>
                  )}
                </Nav>

                  <div
                    onMouseEnter={handleMouseEnter1}
                    onMouseLeave={handleMouseLeave1}
                  >
                    <CustomDropdown
                      items={ExperienceDropdownItems}
                      scrolled={scrolled}
                      isVisible={dropdownVisible1}
                      tag={"mice"}
                    />
                  </div>
               

                  <div
                    onMouseEnter={handleMouseEnter2}
                    onMouseLeave={handleMouseLeave2}
                  >
                    <CustomDropdown
                      items={DestinationDropdownItems}
                      scrolled={scrolled}
                      isVisible={dropdownVisible2}
                      tag={"tours"}
                    />
                  </div>

                  <div
                    onMouseEnter={handleMouseEnter3}
                    onMouseLeave={handleMouseLeave3}
                  >
                    <CustomDropdown
                      items={DMCServicesDropdownItems}
                      scrolled={scrolled}
                      isVisible={dropdownVisible3}
                      tag={"dmc"}
                    />
                  </div>

                  
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            {/* <Button
              variant={scrolled ? "outline-dark" : "outline-light"}
              className="btn-book"
              as={Link}
              to="/book-now"
            >
              Book Now
            </Button> */}
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default MyNavbar;
