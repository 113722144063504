import img6 from "../assets/images/corporate2.jpg";
import img7 from "../assets/images/corporate3.jpg";

const MiceCorporateDropdownItems = [
  {
    path: "/mice-services",
    imageSrc: img6,
    altText: "Our MICE Service",
    label: "OUR MICE SERVICES", 
  },
  {
    path: "/intt-advantage",
    imageSrc: img7,
    altText: "The INTT Advantage",
    label: "THE INTT ADVANTAGE",
  }
];

export default MiceCorporateDropdownItems;