import img6 from "../assets/images/destination-sl.jpg";
import img7 from "../assets/images/otherTours-img.jpg";

const destinationDropdownItems = [
  {
    path: "/destinations/sri-lanka",
    imageSrc: img6,
    altText: "Destinations",
    label: "SRI LANKA",
  },
  {
    path: "/destinations/dubai",
    imageSrc: img7,
    altText: "Destinations",
    label: "DUBAI",
  },
  {
    path: "/destinations/indonesia",
    imageSrc: img7,
    altText: "Destinations",
    label: "INDONESIA",
  },
  {
    path: "/destinations/maldives",
    imageSrc: img7,
    altText: "Destinations",
    label: "MALDIVES",
  }
];

export default destinationDropdownItems;