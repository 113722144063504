import img6 from "../assets/images/DMC-services.jpg";
import img7 from "../assets/images/DMC1.jpg";

const DMCServicesDropdownItems = [
  {
    path: "/dmc-excellence",
    imageSrc: img6,
    altText: "Our DMC Excellence",
    label: "Our DMC Excellence",
  },
  {
    path: "/dmc-services",
    imageSrc: img7,
    altText: "Our DMC Services",
    label: "Our DMC Services",
  }
];

export default DMCServicesDropdownItems;