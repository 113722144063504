// src/api/searchStore.js
import { create } from 'zustand';

// Create a store for search filters
const useSearchStore = create((set, get) => ({
  // Initial state
  filters: {
    country: "Sri Lanka",
    tourCategory: "Featured",
    duration: "",
  },
  
  // Action to update filters
  setFilters: (newFilters) => set((state) => ({
    filters: { ...state.filters, ...newFilters }
  })),
  
  // Action to reset filters
  resetFilters: () => set({
    filters: {
      country: "Sri Lanka",
      tourCategory: "Featured",
      duration: "",
    }
  }),
  
  // Track if filters have been applied (for initial load vs user action)
  filtersApplied: false,
  setFiltersApplied: (value) => set({ filtersApplied: value }),
}));

export default useSearchStore;