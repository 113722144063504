import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import useSearchStore from "../api/searchStore";
import "../components/CSS/CustomDropdown.css";

function CustomDropdown({ scrolled, isVisible, items = [], tag }) {
  const dropdownRef = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const { setFilters } = useSearchStore();

  const updateFilters = (label) => {
    switch(label){
      case "DUBAI":
        setFilters({ country: "Dubai", tourCategory: "", duration: "" });
        break;
      case "INDONESIA":
        setFilters({ country: "Indonesia", tourCategory: "", duration: "" });
        break;
      case "SRI LANKA":
        setFilters({ country: "Sri Lanka", tourCategory: "", duration: "" });
        break;
      case "MALDIVES":
        setFilters({ country: "Maldives", tourCategory: "", duration: "" });
        break;
      default:
        break;
    }
  };

  return (
    <div
      ref={dropdownRef}
      className={`custom-dropdown ${scrolled ? "scrolled" : "nt-scrolled"} ${
        isVisible ? "visible" : isAnimating ? "hiding" : "" }
        ${tag === 'dmc' ? "dmc" : tag === 'tours' ? "tours" : "mice"}
        two-column-dropdown
      `}
    >
      <div id="dropdown-content" className={`dropdown-content ${scrolled ? "scrolled" : "nt-scrolled"}`}>
        <div className="dropdown-grid">
          {items.map((item, index) => (
            <div 
              onClick={() => updateFilters(item.label)} 
              className="dropdown-col" 
              key={index} 
              style={{ "--index": index+1 }}
            >
              <Link to={item.path} className="dropdown-item">
                <div>
                  <p className="nav-item">{item.label}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CustomDropdown;